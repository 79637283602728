import * as deDE from './i18n/de-DE';
import * as enGB from './i18n/en-GB';
import * as esES from './i18n/es-ES';
import * as frFR from './i18n/fr-FR';

import { SystemLanguage } from '@zeiss/ng-vis-common/types';
import { AUTH_TRANSLATIONS } from '@zeiss/ng-vis-auth/i18n';
import { CIM_TRANSLATIONS } from '@zeiss/ng-vis-cim/i18n';
import { DOCUMENTATION_TRANSLATIONS } from '@zeiss/ng-vis-documentation/i18n';
import { GBP_TRANSLATIONS } from '@zeiss/ng-vis-gbp/i18n';
import { GSS_DESIGNER_TRANSLATIONS } from '@zeiss/ng-vis-gss/gss-designer/i18n';
import { GSS_TRANSLATIONS } from '@zeiss/ng-vis-gss/gss/i18n';
import { MDDB_TRANSLATIONS } from '@zeiss/ng-vis-mddb/i18n';
import { OMR_TRANSLATIONS } from '@zeiss/ng-vis-omr/i18n';
import { TST_TRANSLATIONS } from '@zeiss/ng-vis-tst/i18n';
import { UTILS_TRANSLATIONS } from '@zeiss/ng-vis-utils/i18n';
import { VP_DEV_TRANSLATIONS as DEV_TRANSLATIONS } from '@zeiss/ng-vis-vp-dev/i18n';
import { ADMIN_TRANSLATIONS } from './areas/admin/i18n';
import { LOGS_TRANSLATIONS } from './areas/logs/i18n';
import { LPP_TRANSLATIONS } from './areas/lpp/i18n';
import { OTD_TRANSLATIONS } from './areas/otd/i18n';
import { SETTINGS_TRANSLATIONS } from './areas/settings/i18n';
import { STATUS_TRANSLATIONS } from './areas/status/i18n';
import { TASKS_TRANSLATIONS } from './areas/task/i18n';
import { DDP_TRANSLATIONS } from '@zeiss/ng-vis-ddp/i18n';
import { SKILLMATRIX_TRANSLATIONS } from '@zeiss/ng-vis-skillmatrix/i18n';

//Merged translations
export const appEnGB = {
	...enGB.default,
	external: {
		...(OMR_TRANSLATIONS[SystemLanguage['en-GB']] || {}),
		...(DOCUMENTATION_TRANSLATIONS[SystemLanguage['en-GB']] || {}),
		...(CIM_TRANSLATIONS[SystemLanguage['en-GB']] || {}),
		...(TST_TRANSLATIONS[SystemLanguage['en-GB']] || {}),
		...(GSS_TRANSLATIONS[SystemLanguage['en-GB']] || {}),
		...(GSS_DESIGNER_TRANSLATIONS[SystemLanguage['en-GB']] || {}),
		...(ADMIN_TRANSLATIONS[SystemLanguage['en-GB']] || {}),
		...(DEV_TRANSLATIONS[SystemLanguage['en-GB']] || {}),
		...(LOGS_TRANSLATIONS[SystemLanguage['en-GB']] || {}),
		...(LPP_TRANSLATIONS[SystemLanguage['en-GB']] || {}),
		...(MDDB_TRANSLATIONS[SystemLanguage['en-GB']] || {}),
		...(GBP_TRANSLATIONS[SystemLanguage['en-GB']] || {}),
		...(OTD_TRANSLATIONS[SystemLanguage['en-GB']] || {}),
		...(UTILS_TRANSLATIONS[SystemLanguage['en-GB']] || {}),
		...(STATUS_TRANSLATIONS[SystemLanguage['en-GB']] || {}),
		...(SETTINGS_TRANSLATIONS[SystemLanguage['en-GB']] || {}),
		...(TASKS_TRANSLATIONS[SystemLanguage['en-GB']] || {}),
		...(AUTH_TRANSLATIONS[SystemLanguage['en-GB']] || {}),
		...(DDP_TRANSLATIONS[SystemLanguage['en-GB']] || {}),
		...(SKILLMATRIX_TRANSLATIONS[SystemLanguage['en-GB']] || {}),
	},
};
export const appEsES = {
	...esES.default,
	external: {
		...(OMR_TRANSLATIONS[SystemLanguage['es-ES']] || {}),
		...(DOCUMENTATION_TRANSLATIONS[SystemLanguage['es-ES']] || {}),
		...(CIM_TRANSLATIONS[SystemLanguage['es-ES']] || {}),
		...(TST_TRANSLATIONS[SystemLanguage['es-ES']] || {}),
		...(GSS_TRANSLATIONS[SystemLanguage['es-ES']] || {}),
		...(GSS_DESIGNER_TRANSLATIONS[SystemLanguage['es-ES']] || {}),
		...(ADMIN_TRANSLATIONS[SystemLanguage['es-ES']] || {}),
		...(DEV_TRANSLATIONS[SystemLanguage['es-ES']] || {}),
		...(LOGS_TRANSLATIONS[SystemLanguage['es-ES']] || {}),
		...(LPP_TRANSLATIONS[SystemLanguage['es-ES']] || {}),
		...(MDDB_TRANSLATIONS[SystemLanguage['es-ES']] || {}),
		...(GBP_TRANSLATIONS[SystemLanguage['es-ES']] || {}),
		...(OTD_TRANSLATIONS[SystemLanguage['es-ES']] || {}),
		...(UTILS_TRANSLATIONS[SystemLanguage['es-ES']] || {}),
		...(STATUS_TRANSLATIONS[SystemLanguage['es-ES']] || {}),
		...(SETTINGS_TRANSLATIONS[SystemLanguage['es-ES']] || {}),
		...(TASKS_TRANSLATIONS[SystemLanguage['es-ES']] || {}),
		...(AUTH_TRANSLATIONS[SystemLanguage['es-ES']] || {}),
		...(DDP_TRANSLATIONS[SystemLanguage['es-ES']] || {}),
		...(SKILLMATRIX_TRANSLATIONS[SystemLanguage['es-ES']] || {}),
	},
};
export const appDeDE = {
	...deDE.default,
	external: {
		...(OMR_TRANSLATIONS[SystemLanguage['de-DE']] || {}),
		...(DOCUMENTATION_TRANSLATIONS[SystemLanguage['de-DE']] || {}),
		...(CIM_TRANSLATIONS[SystemLanguage['de-DE']] || {}),
		...(TST_TRANSLATIONS[SystemLanguage['de-DE']] || {}),
		...(GSS_TRANSLATIONS[SystemLanguage['de-DE']] || {}),
		...(GSS_DESIGNER_TRANSLATIONS[SystemLanguage['de-DE']] || {}),
		...(ADMIN_TRANSLATIONS[SystemLanguage['de-DE']] || {}),
		...(DEV_TRANSLATIONS[SystemLanguage['de-DE']] || {}),
		...(LOGS_TRANSLATIONS[SystemLanguage['de-DE']] || {}),
		...(LPP_TRANSLATIONS[SystemLanguage['de-DE']] || {}),
		...(MDDB_TRANSLATIONS[SystemLanguage['de-DE']] || {}),
		...(GBP_TRANSLATIONS[SystemLanguage['de-DE']] || {}),
		...(OTD_TRANSLATIONS[SystemLanguage['de-DE']] || {}),
		...(UTILS_TRANSLATIONS[SystemLanguage['de-DE']] || {}),
		...(STATUS_TRANSLATIONS[SystemLanguage['de-DE']] || {}),
		...(SETTINGS_TRANSLATIONS[SystemLanguage['de-DE']] || {}),
		...(TASKS_TRANSLATIONS[SystemLanguage['de-DE']] || {}),
		...(AUTH_TRANSLATIONS[SystemLanguage['de-DE']] || {}),
		...(DDP_TRANSLATIONS[SystemLanguage['de-DE']] || {}),
		...(SKILLMATRIX_TRANSLATIONS[SystemLanguage['de-DE']] || {}),
	},
};
export const appFrFR = {
	...frFR.default,
	external: {
		...(OMR_TRANSLATIONS[SystemLanguage['fr-FR']] || {}),
		...(DOCUMENTATION_TRANSLATIONS[SystemLanguage['fr-FR']] || {}),
		...(CIM_TRANSLATIONS[SystemLanguage['fr-FR']] || {}),
		...(TST_TRANSLATIONS[SystemLanguage['fr-FR']] || {}),
		...(GSS_TRANSLATIONS[SystemLanguage['fr-FR']] || {}),
		...(GSS_DESIGNER_TRANSLATIONS[SystemLanguage['fr-FR']] || {}),
		...(ADMIN_TRANSLATIONS[SystemLanguage['fr-FR']] || {}),
		...(DEV_TRANSLATIONS[SystemLanguage['fr-FR']] || {}),
		...(LOGS_TRANSLATIONS[SystemLanguage['fr-FR']] || {}),
		...(LPP_TRANSLATIONS[SystemLanguage['fr-FR']] || {}),
		...(MDDB_TRANSLATIONS[SystemLanguage['fr-FR']] || {}),
		...(GBP_TRANSLATIONS[SystemLanguage['fr-FR']] || {}),
		...(OTD_TRANSLATIONS[SystemLanguage['fr-FR']] || {}),
		...(UTILS_TRANSLATIONS[SystemLanguage['fr-FR']] || {}),
		...(STATUS_TRANSLATIONS[SystemLanguage['fr-FR']] || {}),
		...(SETTINGS_TRANSLATIONS[SystemLanguage['fr-FR']] || {}),
		...(TASKS_TRANSLATIONS[SystemLanguage['fr-FR']] || {}),
		...(AUTH_TRANSLATIONS[SystemLanguage['fr-FR']] || {}),
		...(DDP_TRANSLATIONS[SystemLanguage['fr-FR']] || {}),
		...(SKILLMATRIX_TRANSLATIONS[SystemLanguage['fr-FR']] || {}),
	},
};
