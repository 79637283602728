import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	Inject,
	OnDestroy,
	ViewChild,
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { NavigationEnd, Router } from '@angular/router';
import { AUTHORIZED_ROUTES_SERVICE, SETTINGS_SERVICE, THEME_SERVICE } from '@zeiss/ng-vis-common/injection-tokens';
import { TutorialTopics } from '@zeiss/ng-vis-tutorial/types';
import { Subject, BehaviorSubject, debounceTime, filter, takeUntil, combineLatestWith } from 'rxjs';
import { App } from 'src/environments/shared';
import {
	AuthorizedRoutesServiceProvider,
	SettingsServiceProvider,
	ThemeServiceProvider,
} from '@zeiss/ng-vis-common/types';
import { NgVisVpSettings } from '../settings/settings';
import { AUTH_SERVICE } from '@zeiss/ng-vis-vp-auth/injection-tokens';
import { AuthServiceProvider, VpRoute } from '@zeiss/ng-vis-vp-auth/types';

@Component({
	selector: 'vis-menubar',
	templateUrl: './menubar.component.html',
	styleUrls: ['./menubar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenubarComponent implements AfterViewInit, OnDestroy {
	app = App;
	tutorialTopics = TutorialTopics;

	private destroy$ = new Subject<void>();

	hoverBarMenuIndex$ = new BehaviorSubject<number>(-1);
	hoverPopupMenuIndex$ = new BehaviorSubject<number>(-1);
	currMenuTrigger: MatMenuTrigger | null = null;
	popupPanelClass: 'popup-closed-bar' | 'popup-opened-bar' = 'popup-closed-bar';

	private readonly scrollCurrentNavItemIntoView$ = new Subject<void>();
	@ViewChild('menubar') private menubar?: ElementRef<HTMLElement>;

	constructor(
		@Inject(THEME_SERVICE) public theme: ThemeServiceProvider,
		@Inject(SETTINGS_SERVICE) public settingsService: SettingsServiceProvider<NgVisVpSettings>,
		@Inject(AUTH_SERVICE) public auth: AuthServiceProvider,
		@Inject(AUTHORIZED_ROUTES_SERVICE) public routesService: AuthorizedRoutesServiceProvider,
		private router: Router,
		private changeRef: ChangeDetectorRef
	) {
		this.scrollCurrentNavItemIntoView$
			.pipe(takeUntil(this.destroy$), debounceTime(500))
			.subscribe(() => this.scrollCurrentNavItemIntoView());

		this.routesService.loadingStart$.pipe(takeUntil(this.destroy$), debounceTime(500)).subscribe(() => {
			this.changeRef.detectChanges();
			this.scrollCurrentNavItemIntoView$.next();
		});

		this.router.events
			.pipe(
				takeUntil(this.destroy$),
				filter((event) => event instanceof NavigationEnd)
			)
			.subscribe(() => this.scrollCurrentNavItemIntoView$.next());
	}

	ngAfterViewInit(): void {
		setTimeout(() => {
			if (this.settingsService.settings.SystemNavBarExtended === true) {
				this.menubarOpen();
			}
			this.scrollCurrentNavItemIntoView$.next();
		}, 300);

		this.hoverBarMenuIndex$
			.pipe(debounceTime(300), combineLatestWith(this.hoverPopupMenuIndex$), takeUntil(this.destroy$))
			.subscribe(([index, hoverPopupMenuIndex]) => {
				if (hoverPopupMenuIndex > -1) return;
				this.currMenuTrigger?.closeMenu();
				if (index > -1) {
					this.currMenuTrigger?.openMenu();
				}
			});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	scrollCurrentNavItemIntoView() {
		const activeNavBarItem = document.getElementById('navBarItemActive');
		activeNavBarItem?.scrollIntoView({ behavior: 'smooth' });
	}

	enterBarMenu(menuTrigger: MatMenuTrigger, index: number) {
		this.hoverBarMenuIndex$.next(index);
		this.currMenuTrigger = menuTrigger;
		this.currMenuTrigger.menu.overlayPanelClass = 'popup-overlay';
	}

	leaveBarMenu() {
		this.hoverBarMenuIndex$.next(-1);
	}

	enterPopupMenu(index: number) {
		this.hoverPopupMenuIndex$.next(index);
	}

	leavePopupMenu() {
		this.hoverPopupMenuIndex$.next(-1);
	}

	toggleMenubarExpanded() {
		if (this.menubar?.nativeElement?.getAttribute('opened') !== null) {
			this.menubarClose();
		} else {
			this.menubarOpen();
		}
	}

	menubarOpen() {
		this.menubar?.nativeElement?.setAttribute('opened', '');
		this.settingsService.settings = { SystemNavBarExtended: true };
		this.popupPanelClass = 'popup-opened-bar';
		this.changeRef.detectChanges();
	}

	menubarClose() {
		this.menubar?.nativeElement?.removeAttribute('opened');
		this.settingsService.settings = { SystemNavBarExtended: false };
		this.popupPanelClass = 'popup-closed-bar';
	}

	get routeConfigMain() {
		return this.routesService.authorizedRoutes$.value.filter((route) => route.data && route.data.showInMenu);
	}

	routeConfigSub(route: VpRoute) {
		return (route.data?.routes ?? []).filter((route) => route.data?.showInMenu === true);
	}
}
