import { VpSettings } from '@zeiss/ng-vis-vp-auth/types';
import { AnnouncementShowState } from '../announcement/announcement';

export const LogPre = '🔧 [Settings Vision Portal]';

export interface NgVisVpSettings extends VpSettings {
	SystemAnnouncementsDisabled: boolean;
	SystemAnnouncementsStates: AnnouncementShowState[];
}

export const VP_SETTINGS: NgVisVpSettings = {
	SystemAnnouncementsDisabled: false,
	SystemAnnouncementsStates: [],
	SystemBookmarks: [],
	SystemLanguage: 'os-setting',
	SystemSessionId: '',
	SystemTheme: 'os-setting',
	SystemNavBarExtended: true,
};
