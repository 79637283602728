import { OAuthModule } from 'angular-oauth2-oidc';
import { BehaviorSubject } from 'rxjs';
import { Environment } from 'src/environments/environment';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, Inject, NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsModule } from '@ngxs/store';
import {
	GLOBAL_SEARCH_DATA_PROVIDER,
	GlobalSearchDataService,
	GlobalSearchModule,
	NgVisToastModule,
} from '@zeiss/ng-vis-common/components';
import { ENVIRONMENT$, LOCALE, SETTINGS_SERVICE, TASK_API_SERVICE } from '@zeiss/ng-vis-common/injection-tokens';
import { StorageSettingsService } from '@zeiss/ng-vis-common/services';
import { SettingsServiceProvider, SystemLanguage } from '@zeiss/ng-vis-common/types';
import { NgVisTutorialModule } from '@zeiss/ng-vis-tutorial';
import { VpAuthModule } from '@zeiss/ng-vis-vp-auth';
import { VpAuthStateComponent } from '@zeiss/ng-vis-vp-auth/components';
import { MdmEsbSubscriptionKeyInterceptor } from '@zeiss/ng-vis-vp-auth/interceptors';

import { App } from '../environments/shared';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { appDeDE, appEnGB, appEsES, appFrFR } from './app.translations';
import { SplashComponent } from './areas/splash/splash.component';
import { AccessFaqDialogComponent } from './common/access-faq-dialog/access-faq-dialog.component';
import { AnnouncementApiService } from './common/announcement/announcement-api.service';
import { ApplicationInsightsService } from './common/application-insights/application-insights.service';
import { PendingChangesGuard } from './common/change-guard/change-guard';
import { HeaderbarComponent } from './common/headerbar/headerbar.component';
import { LanguageService } from './common/language/language.service';
import { MenubarComponent } from './common/menubar/menubar.component';
import { CacheRouteReuseStrategy } from './common/router/route.strategy';
import { NgVisVpSettings, VP_SETTINGS } from './common/settings/settings';
import { TaskApiService } from './common/task/task-api.service';
import { EventModule } from './shared/event/event.module';
import { GlobalImportsModule } from './shared/global-imports.module';
import { SharedInjectionsModule } from './shared/shared-injections.module';

let locale$ = new BehaviorSubject<SystemLanguage>(App.DefaultLanguage);
let env$ = new BehaviorSubject(Environment.AppMeta.Environment);

@NgModule({
	declarations: [AppComponent, HeaderbarComponent, MenubarComponent, SplashComponent],
	imports: [
		AccessFaqDialogComponent,
		VpAuthModule,
		SharedInjectionsModule,
		BrowserModule,
		AppRoutingModule,
		OAuthModule.forRoot(),
		TranslateModule.forRoot({
			isolate: true,
			extend: false,
			useDefaultLang: true,
			defaultLanguage: App.DefaultLanguage,
		}),
		GlobalImportsModule,
		BrowserAnimationsModule,
		MatMenuModule,
		MatDialogModule,
		NgVisTutorialModule.forRoot({
			environment: Environment.AppMeta.Environment,
		}),
		NgxsModule.forRoot(),
		NgxsRouterPluginModule.forRoot(),
		NgxsReduxDevtoolsPluginModule.forRoot(),
		EventModule,
		NgVisToastModule,
		VpAuthStateComponent,
		HttpClientModule,
		GlobalSearchModule,
	],
	providers: [
		ApplicationInsightsService,
		{ provide: ENVIRONMENT$, useValue: env$ },
		{ provide: RouteReuseStrategy, useClass: CacheRouteReuseStrategy },
		{ provide: TASK_API_SERVICE, useClass: TaskApiService },
		{ provide: LOCALE, useValue: locale$ },
		{
			provide: SETTINGS_SERVICE,
			useValue: new StorageSettingsService<NgVisVpSettings>('VP_SETTINGS', VP_SETTINGS, localStorage),
		},
		PendingChangesGuard,
		AnnouncementApiService,
		{ provide: HTTP_INTERCEPTORS, multi: true, useClass: MdmEsbSubscriptionKeyInterceptor }, // used for the task api
		{ provide: GLOBAL_SEARCH_DATA_PROVIDER, useClass: GlobalSearchDataService },
	],
	bootstrap: [AppComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
	constructor(
		private translateService: TranslateService,
		private languageService: LanguageService,
		@Inject(SETTINGS_SERVICE) public settingsService: SettingsServiceProvider<NgVisVpSettings>
	) {
		this.translateService.setTranslation(SystemLanguage['en-GB'], appEnGB, true);
		this.translateService.setTranslation(SystemLanguage['de-DE'], appDeDE, true);
		this.translateService.setTranslation(SystemLanguage['es-ES'], appEsES, true);
		this.translateService.setTranslation(SystemLanguage['fr-FR'], appFrFR, true);

		this.languageService.Language$.subscribe((language) => locale$.next(language ?? App.DefaultLanguage));
	}
}
